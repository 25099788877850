import './App.css';
import {useEffect, useState} from "react";
import {v4 as uuidv4} from 'uuid';
import LoadingScreen from './assets/CR_Loading_screen_No_Logo.png'
import MobileLoadingScreen from './assets/CR_Loading_screen_No_Logo_Mobile.png'
import LoadingLogo from './assets/CR_Logo_Small_SIze.png'
// import FullscreenButton from './assets/switch-to-full-screen-button.png'

function App() {
    const [id, setId] = useState(localStorage.getItem('user_id') || uuidv4());
    const [play, setPlay] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 661);

    const baseURL = process.env.REACT_APP_GAME_BASE_URL;

    const url = `${baseURL}?user_id=${id}&currency=usd&lang=en&integrator=demo&operator=demo&init_balance=100000`

    const runPlay = () => {
        setPlay(true);
        window.open(url, '_blank').focus();
    }

    const resetUser = () => {
        const newId = uuidv4();
        setId(newId);
        localStorage.setItem('user_id', newId);
    };

    const goFullscreen = () => {
        var element = document.getElementById("unityIframe");
        if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
        }
    }

    useEffect(() => {
        uuidv4();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 661);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    return (
        <div className="App">
            {!play ? (
                <div className={'main-container'}>
                    <img className='unity-loading-screen' src={isMobile ? MobileLoadingScreen : LoadingScreen}/>
                    <img className='unity-loading-screen-logo' src={LoadingLogo}/>
                    <button
                        className='unity-play-button'
                        onClick={runPlay}
                    >
                        Play
                    </button>
                </div>
            ) : (
                <>
                    <div className={'main-container'}>
                        <img className='unity-loading-screen' src={isMobile ? MobileLoadingScreen : LoadingScreen}/>
                        <img className='unity-loading-screen-logo' src={LoadingLogo}/>
                        <button
                            className='unity-play-button'
                            onClick={runPlay}
                        >
                            Play
                        </button>
                    </div>
                    {play && (
                        <>
                            {/*<button*/}
                            {/*    className='unity-fullscreen-button'*/}
                            {/*    onClick={() => goFullscreen()}*/}
                            {/*>*/}
                            {/*    <img className='unity-fullscreen-button-icon' src={FullscreenButton}/>*/}
                            {/*</button>*/}
                            <button
                                className="user-reset-button"
                                onClick={() => resetUser()}
                            >
                                User Reset
                            </button>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default App;
